import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import '../../assets/styles/components/hero-banner.scss';
import Carousel from 'react-multi-carousel';

const HeroBanner = ({ fetchedData }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 8000, min: 540 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 540, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="carousel-wrapper">
      <Carousel
        infinite
        removeArrowOnDeviceType="mobile"
        autoPlay
        autoPlaySpeed={3000}
        itemClass="image-item"
        containerClass="carousel-container"
        responsive={responsive}
      >
        {fetchedData.carousel
          && fetchedData.carousel.map((image) => {
            const img = getImage(image);
            return (
              <GatsbyImage image={img} alt={image.alt || ''} />
            );
          })}
      </Carousel>
    </div>
  );
};

HeroBanner.propTypes = {
  fetchedData: PropTypes.shape().isRequired,
};

export default HeroBanner;
