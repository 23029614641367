import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const detailsQuery = graphql`
  query DefaultSEOQuery {
    datoCmsSite {
      globalSeo {
        facebookPageUrl
        siteName
        fallbackSeo {
          description
          image {
            url
          }
          title
          twitterCard
        }
      }
    }
  }
`;

function SEO(
  {
    description,
    lang,
    meta,
    keywords,
    image,
    title,
  },
) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const translation = data.datoCmsSite;
        const metaDescription = description || translation.globalSeo.fallbackSeo.description;
        const metaTitle = title || translation.globalSeo.fallbackSeo.title;
        const metaImage = image || translation.globalSeo.fallbackSeo.image.url;

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={metaTitle}
            titleTemplate={metaTitle}
            meta={[
              {
                name: 'description',
                content: metaDescription,
              },
              {
                property: 'og:title',
                content: metaTitle,
              },
              {
                property: 'og:description',
                content: metaDescription,
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                property: 'twitter:card',
                content: 'summary' || translation.globalSeo.fallbackSeo.twitterCard,
              },
              {
                property: 'twitter:creator',
                content: '#thecroftpractice',
              },
              {
                property: 'twitter:title',
                content: metaTitle,
              },
              {
                property: 'twitter:description',
                content: metaDescription,
              },
              {
                property: 'og:image',
                content: metaImage,
              },
              {
                property: 'og:image:secure_url',
                content: metaImage,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                    name: 'keywords',
                    content: keywords.join(', '),
                  }
                  : [],
              )
              .concat(meta)}
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  description: '',
  image: null,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.string),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
};

export default SEO;
