import React, { useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import '../assets/styles/components/header.scss';

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      datoCmsHeader {
        navigation {
          link
          linkText
          externalLink
          id
        }
        navigationWithSubNav {
          externalLink
          link
          text
          subNavigation {
            link
            linkText
            externalLink
            id
          }
        }
      }
    }
  `);
  const fetchedData = data.datoCmsHeader;
  const [navState, setNavState] = useState('');
  const createLink = (text, link, isExternal, id) => {
    if (isExternal) {
      return (
        <a id={id} href={link}>
          {text}
        </a>
      );
    }
    return (
      <Link id={id} to={link} activeClassName="active">
        {text}
      </Link>
    );
  };

  const handleHamburger = (e) => {
    e.preventDefault();
    let navClass = '';
    if (navState === '') {
      navClass = 'nav-open';
    }
    setNavState(navClass);
  };

  return (
    <header>
      <Link className="logo-link" to="/" aria-label="Site logo">
        <StaticImage className="logo" src="../images/logo.png" alt="croft practice" />
      </Link>
      <ul className={`navigation ${navState}`}>
        {fetchedData.navigationWithSubNav
          && fetchedData.navigationWithSubNav.map((parentLink) => {
            const subMenu = parentLink.subNavigation.map((childLink) => (
              <li className="sub-link">
                {createLink(
                  childLink.linkText,
                  childLink.link,
                  childLink.externalLink,
                  childLink.id,
                )}
              </li>
            ));
            return (
              <li className="nav-with-sub">
                {createLink(
                  parentLink.text,
                  parentLink.link,
                  parentLink.externalLink,
                  parentLink.id,
                )}
                <ul className="sub-menu">{subMenu}</ul>
              </li>
            );
          })}
        {fetchedData.navigation
          && fetchedData.navigation.map((link) => {
            return (
              <li className="nav-no-sub">
                {createLink(link.linkText, link.link, link.externalLink, link.id)}
              </li>
            );
          })}
      </ul>
      <button
        aria-label="hamburger"
        type="button"
        onClick={(e) => handleHamburger(e)}
        className={`hamburger-wrapper ${navState}`}
      >
        <span className="top" />
        <span className="mid" />
        <span className="bot" />
      </button>
    </header>
  );
};

export default Header;
