import React, { useState, useEffect } from 'react';
import '../assets/styles/components/gdpr-banner.scss';

/* global window */
const GdprBanner = () => {
  const [showBanner, setShowBanner] = useState('');

  const close = () => {
    setShowBanner('hidden');
  };

  const accept = () => {
    window.localStorage.setItem('cookie', 'accepted');
    close();
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (window.localStorage.getItem('cookie') === 'accepted') {
        setShowBanner('hidden');
      }
    }
    return () => { isMounted = false; };
  });

  return (
    <div className={`gdpr-banner ${showBanner}`}>
      <div className="center-wrapper">
        <span className="text">
          We use cookies to ensure that we give you the best experience on our website. If you
          continue to use this site we will assume that you are happy with it.
        </span>
        <button className="accept" onClick={accept} type="button">
          Ok
        </button>
      </div>
      <button className="close" aria-label="GDPR close" onClick={close} type="button" />
    </div>
  );
};

export default GdprBanner;
