import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import '../assets/styles/components/contact-bar.scss';

const ContactBar = ({ fetchedData }) => {
  const imageFile = getImage(fetchedData.personalPicture);

  const sponsorThumbnail = (thumbnail) => {
    const image = getImage(thumbnail.thumbnail);
    if (thumbnail.link) {
      return (
        <a href={thumbnail.link} aria-label="Sponsor logo">
          <GatsbyImage image={image} alt={thumbnail.thumbnail.alt || 'sponsor'} />
        </a>
      );
    }

    return (
      <span>
        <GatsbyImage image={image} alt={thumbnail.thumbnail.alt || 'sponsor'} />
      </span>
    );
  };

  return (
    <div className="contact-bar-wrapper">
      <GatsbyImage
        className="profile-pic"
        image={imageFile}
        alt={fetchedData.personalPicture.alt || ''}
      />
      <div className="name">{fetchedData.yourName}</div>
      <Link className="contact" to="/contact">Email me </Link>
      <a className="contact" href={`tel:${fetchedData.homeNumber}`}>{fetchedData.homeNumber}</a>
      <a className="contact" href={`tel:${fetchedData.mobileNumber}`}>{fetchedData.mobileNumber}</a>
      <div className="helped-list">
        I have helped clients with:
        <div
          className="help-list-container"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: fetchedData.iveHelpedClientsWith }}
        />
      </div>
      <div
        className="motto"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: fetchedData.motto }}
      />
      <div className="sponsor-container">
        {fetchedData.sponsorThumbnails
          && fetchedData.sponsorThumbnails.map((obj) => sponsorThumbnail(obj))}
      </div>
    </div>
  );
};

ContactBar.propTypes = {
  fetchedData: PropTypes.shape().isRequired,
};

export default ContactBar;
