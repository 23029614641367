/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Header from './header';
import ContactBar from './contactBar';
import HeroBanner from './blocks/heroBanner';
import GdprBanner from './gdprBanner';
import '../assets/styles/components/footer.scss';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      datoCmsHeroBanner {
        carousel {
          gatsbyImageData(
            width: 900, 
            placeholder: NONE
          )
          alt
        }
      }
      datoCmsRightBar {
        yourName
        mobileNumber
        homeNumber
        iveHelpedClientsWith
        motto
        personalPicture {
          gatsbyImageData(width: 500, placeholder: BLURRED)
          alt
        }
        sponsorThumbnails {
          thumbnail {
            gatsbyImageData(width: 250, placeholder: BLURRED)
            alt
          }
          link
        }
      }
      datoCmsBackgroundImage {
        bgImage {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
      }
    }
  `);

  const imageFile = getImage(data.datoCmsBackgroundImage.bgImage);

  return (
    <>
      <Header />
      <div>
        <main>
          <div className="image-bg">
            <GatsbyImage image={imageFile} alt="background image" />
          </div>
          <div className="content">
            <div className="body">
              <HeroBanner fetchedData={data.datoCmsHeroBanner} />
              {children}
            </div>
            <ContactBar fetchedData={data.datoCmsRightBar} />
          </div>
        </main>
        <footer>
          The Croft Practice ©
          {' '}
          {new Date().getFullYear()}
          {' '}
          <Link to="/privacy-policy">Privacy Statement</Link>
        </footer>
        <GdprBanner />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
